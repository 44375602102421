// @flow strict
import React from "react";
import { Link, navigate } from "gatsby";
import Content from "./Content";
import Meta from "./Meta";
import * as styles from "./Post.module.scss";

import "katex/dist/katex.min.css";

const Post = ({ post, page }) => {
  const { body } = post;
  const { tags, title, date, authors } = post.frontmatter;
  const back_url = page === 1 ? "/articles" : `/articles/${page}`;
  return (
    <div className={styles["post"]}>
      <Link className={styles["post__homeButton"]} to="/">
        Back to Home
      </Link>
      <Link className={styles["post__postButton"]} to={back_url}>
        Back to Articles
      </Link>

      <div className={styles["post__content"]}>
        <Content body={body} title={title} authors={authors} />
      </div>

      <div className={styles["post__footer"]}>
        <Meta date={date} />
      </div>
    </div>
  );
};

export default Post;
