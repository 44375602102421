// @flow strict
import React from "react";
import { meta, meta__date } from "./Meta.module.scss";

const Meta = ({ date }) => (
  <div className={meta}>
    <p className={meta__date}>
      Published{" "}
      {new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })}
    </p>
  </div>
);

export default Meta;
