// @flow strict
import React from "react";
import * as styles from "./Content.module.scss";
import Link from "../../../utils/link";
import { StaticImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
const Paper = ({ children }) => <div className={styles.paper}>{children}</div>;

function Author({ name, website, lab }) {
  var author = website ? <Link to={website}>{name}</Link> : <span>{name}</span>;

  var lab = lab.url ? (
    <Link to={lab.url}>{lab.name}</Link>
  ) : (
    <span key={lab.name}>{lab.name}</span>
  );
  return (
    <li className={styles.content__listItem}>
      {author}
      {lab}
    </li>
  );
}

function Content({ body, authors, title }) {
  return (
    <div className={styles["content"]}>
      <h1 className={styles["content__title"]}>{title}</h1>
      <ul className={styles.content__list}>
        {authors &&
          authors.map((author) => <Author {...author} key={author.name} />)}
      </ul>
      <div className={styles["content__body"]}>
        <MDXProvider components={{ Paper }}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </div>
    </div>
  );
}
export default Content;
